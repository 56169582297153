<template>
  <div class="online-shop">
    <v-app-bar>
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
    </v-app-bar>
    <v-container>
      <h1 class="font-weight-bold">Online Shop</h1>
      <v-row class="menu-shop-online" dense>
        <v-col v-for="mrktplce in mrktplc" :key="mrktplce.name" :cols="12">
          <v-skeleton-loader
            :loading="loading"
            v-if="firstLoad"
            type="image"
            height="95px"
          ></v-skeleton-loader>
          <v-card v-bind:href="mrktplce.link" v-if="!firstLoad">
            <v-img
              :src="cards[mrktplce.name].src"
              class="white--text align-end"
              height="95px"
              style="
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                  0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
              "
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <BottomNav />
  </div>
</template>
<script>
import BottomNav from "../components/BottomNav.vue";
import { GetDetailProduct } from "../services/api";

export default {
  name: "OnlineShop",
  components: { BottomNav },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
  data: () => ({
    produk: [],
    mrktplc: [
      {
        name: "",
        link: "",
      },
    ],
    cards: {
      tokopedia: {
        color: "#1F7087",
        src: "https://i.ibb.co/wBn9XDq/tokopedia-2x.png",
        url: "https://tokopedia.com/",
      },
      shopee: {
        color: "#952175",
        src: "https://i.ibb.co/TRhXCk4/shopee-2x.png",
        url: "https://shopee.co.id/",
      },
      lazada: {
        color: "#952175",
        src: "https://i.ibb.co/GsKGTR9/lazada-2x.png",
        url: "https://lazada.co.id",
      },
      jdid: {
        color: "#952175",
        src: require("../assets/jd.id@2x.png"),
        url: "https://www.jd.id/",
      },
      bukalapak: {
        color: "#952175",
        src: require("../assets/bukalapak@2x.png"),
        url: "https://www.bukalapak.com/",
      },
    },
    loading: true,
    firstLoad: true,
  }),
  mounted() {
    GetDetailProduct(this.$route.params.id)
      .then((res) => {
        this.produk = res.data.data;
        console.log(this.produk);
        for (let i in this.produk.link_marketplace) {
          this.mrktplc[i].name = this.produk.link_marketplace[i].marketplace;
          this.mrktplc[i].link = this.produk.link_marketplace[i].link;
        }
        console.log(this.cards);
        //   this.items[0].link = "/online-shop/"+this.produk.id
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      if (this.firstLoad) this.firstLoad = false;
      this.loading = false;
    }, 1000);
  },
};
</script>

<style>
.menu-shop-online .v-card.v-sheet {
  box-shadow: none;
  border: 1px #eee solid;
  border-radius: 6px;
}
.online-shop h1 {
  font-size: 31px;
  margin-bottom: 27px !important;
  margin-top: 19px;
}
.row.menu-shop-online .col {
  padding: 5px !important;
}
.online-shop {
  background: #f8f8f8;
  height: 100vh;
}
</style>